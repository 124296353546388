<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between mb-1">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'costs'">{{
              $formatter(props.row.costs)
            }}</span>
            <span v-else-if="props.column.field === 'Company.companyName'">
              {{ props.row.reseller }}
            </span>
            <span v-else-if="props.column.field === 'salesPartner'">{{
              props.row.salesPartner
            }}</span>
            <span v-else-if="props.column.field === 'created_at'">{{
              $dateFormatter(props.row.created_at)
            }}</span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <b-row>
        <b-col cols="12" lg="4" md="6" v-for="row in rows" :key="row.id">
          <div class="card">
            <div class="card-header">
              <h5>{{ row.companyName }}</h5>
            </div>
            <div class="card-body">
              <ul class="card-list">
                <li>
                  <div class="left">
                    <strong>{{ $t("Reseller") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.reseller }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Sales Partner") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.salesPartner }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Costs") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      $formatter(row.costs, $i18n.locale, "EUR", false, 2, 2)
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Credits") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.credits }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Session ID") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.sessionId }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Status") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.status }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Created Date") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ $dateFormatter(row.created_at) }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-center mt-2">
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--==================================-->
    <!--==================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { props } from "vue-prism-component";

export default {
  components: {
    PageHeader,
    Multiselect,
  },

  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t(""),
          icon: "PlusIcon",
          path: "",
          permission: "",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Payment History"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Company"),
          field: "companyName",
        },
        {
          label: this.$t("Reseller"),
          field: "Company.companyName",
        },
        {
          label: this.$t("Sales Partner"),
          field: "salesPartner",
        },
        {
          label: this.$t("Costs"),
          field: "costs",
        },
        {
          label: this.$t("Credits"),
          field: "credits",
        },
        {
          label: this.$t("Session ID"),
          field: "sessionId",
        },
        {
          label: this.$t("Status"),
          field: "status",
        },
        {
          label: this.$t("Created Date"),
          field: "created_at",
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      rows: [],
      searchTerm: "",
    };
  },

  async created() {
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      if (params[0].field == "salesPartner") {
        this.form.sortBy = "Company.companyName";
      }
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch(
          "packages/getPaymentHistory",
          {
            page: this.page,
            ...this.form,
            perPage: this.pageLength,
          }
        );
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store.dispatch("", id).finally(() => {
            this.loadItems();
          });
        }
      });
    },
  },
};
</script>
